<template>
  <c-box>
    <c-box
      width="100%"
      :max-width="['80%', '1056px']"
      display="flex"
      mx="auto"
      :flex-direction="['column', 'row']"
      justify-content="center"
      align-items="center"
      :color="['neutral.black', 'neutral.superDarkGray']"
      :font-size="['14px', '28px']"
      :font-weight="['600', '700']"
      :padding="['10px 10px 0 10px', '10px']"
      :gap="['0px', '10px']"
      border-bottom="1px solid #C4C4C4"
    >
      Kamu cocok program yang mana?
      <c-box as="span">
        Pilih sesuai target dan kondisi
      </c-box>
    </c-box>

    <!-- Table Comparation Target -->
    <c-box
      v-if="programTargets.length"
      max-width="1057px"
      width="100%"
      mx="auto"
      :py="['8px', '16px']"
    >
      <c-box
        pos="relative"
        :display="['none', 'table']"
        as="table"
        width="100%"
        class="table-comparation"
        z-index="3"
      >
        <c-box
          as="thead"
          color="#FFF"
          background-color="program.400"
          font-weight="700"
          border-radius="8px"
        >
          <c-box
            v-chakra="{
              '>:first-child': {
                borderLeft: '1px solid',
                borderColor: 'program.400',
              },
              '>:last-child': {
                borderRight: '1px solid',
                borderColor: 'program.400',
              },
              '>:not(:last-child):not(:first-child)': {
                borderRight: '1px solid #333333',
                borderLeft: '1px solid #333333',
              },
            }"
            as="tr"
          >
            <c-box
              as="th"
              text-align="center"
              font-size="20px"
              py="1rem"
            >
              Fasilitas Layanan
            </c-box>
            <c-box
              v-for="(item, i) in programTargets"
              :key="`th-${i}`"
              as="th"
              text-align="center"
              font-size="20px"
              py="1rem"
            >
              {{ item?.programsService?.split(' ')[0] }}
            </c-box>
          </c-box>
        </c-box>

        <c-box
          v-chakra="{
            '> tr': {
              borderLeft: '1px solid var(--program-400)',
              borderRight: '1px solid var(--program-400)',
            },
          }"
          as="tbody"
          background-color="#FFF"
          color="#000"
          font-size="16px"
        >
          <c-box
            v-for="(facility, i) in programFacilities"
            :key="`tr-${i}`"
            v-chakra="{
              '>:not(:last-child):not(:first-child)': {
                borderRight: '1px solid #C4C4C4',
                borderLeft: '1px solid #C4C4C4',
              },
            }"
            as="tr"
            :border-bottom="i == programFacilities.length - 1 ? '1px solid var(--program-400)' : '1px solid #C4C4C4'"
          >
            <c-box
              v-for="k in programTargets.length + 1"
              v-show="
                k <= 2 ||
                  mapCountOfSameValues[facility][
                    getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation)
                  ] < 2 ||
                  isCheckList(getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation))
              "
              :key="`td-${i}-${k}`"
              as="td"
              :text-align="k === 1 ? 'left' : 'center'"
              :colspan="
                (k === 1 || isCheckList(getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation)))
                  ? '1'
                  : mapCountOfSameValues[facility][
                    getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation)
                  ]
              "
            >
              <c-box
                v-if="k === 1"
                width="100%"
                padding="12px"
              >
                {{ facility }}
              </c-box>
              <c-box
                v-else
                width="100%"
                padding="12px"
                display="flex"
                justify-content="center"
                white-space="pre-line"
              >
                <inline-svg
                  v-if="isCheckList(getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation))"
                  :src="
                    checkIcon(getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation))
                      ? iconCircleCheck
                      : iconCircleClose
                  "
                  class="icon-list"
                  :fill="
                    checkIcon(getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation))
                      ? 'var(--program-400)'
                      : '#D32737'
                  "
                />
                <c-box v-html="removeIcon(getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation))" />
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </c-box>

      <c-box
        pos="relative"
        :display="['block', 'none']"
        z-index="3"
        width="100%"
        padding="0 10px"
      >
        <c-grid
          v-chakra="{
            '> :not(:first-child) > div': {
              borderLeft: '1px solid #333333',
            },
          }"
          w="100%"
          :template-columns="`repeat(${programTargets.length}, 1fr)`"
          background-color="program.400"
          color="#FFF"
          font-size="14px"
          font-weight="500"
          border-top-left-radius="8px"
          border-top-right-radius="8px"
          border="1px solid"
          border-color="program.400"
        >
          <c-box
            v-for="item in programTargets"
            :key="item?.id"
            w="100%"
            py="6px"
          >
            <c-flex
              w="100%"
              flex-direction="column"
              align-items="center"
            >
              <c-text font-size="14px">
                {{ item?.programsService?.split(' ')[0] }}
              </c-text>
            </c-flex>
          </c-box>
        </c-grid>
        <c-box
          p="16px 8px"
          color="#000"
          font-size="12px"
          font-weight="400"
          background-color="#FFF"
          border-bottom-left-radius="8px"
          border-bottom-right-radius="8px"
          border-left="1px solid"
          border-right="1px solid"
          border-bottom="1px solid"
          border-color="program.400"
        >
          <c-box
            v-for="(facility, index) in programFacilities"
            :key="`facility-${index}`"
            mb="8px"
          >
            <c-box
              p="6px 12px"
              background-color="program.100"
              text-align="center"
            >
              {{ facility }}
            </c-box>
            <c-grid
              v-if="
                mapCountOfSameValues[facility][
                  getFacilityValue(facility, programTargets[0]?.productFacilitiesComparation)
                ] < 2 ||
                  isCheckList(getFacilityValue(facility, programTargets[0]?.productFacilitiesComparation))
              "
              v-chakra="{
                '> :not(:first-child)': {
                  borderLeft: '1px solid #C4C4C4',
                },
              }"
              w="100%"
              :template-columns="`repeat(${programTargets.length}, 1fr)`"
              text-align="center"
            >
              <c-flex
                v-for="j in programTargets.length"
                :key="j"
                w="100%"
                p="6px"
                flex-direction="column"
                align-items="center"
                white-space="pre-line"
              >
                <inline-svg
                  v-if="isCheckList(getFacilityValue(facility, programTargets[j - 1]?.productFacilitiesComparation))"
                  :src="
                    checkIcon(getFacilityValue(facility, programTargets[j - 1]?.productFacilitiesComparation))
                      ? iconCircleCheck
                      : iconCircleClose
                  "
                  class="icon-list"
                  :fill="
                    checkIcon(getFacilityValue(facility, programTargets[j - 1]?.productFacilitiesComparation))
                      ? 'var(--program-400)'
                      : '#D32737'
                  "
                />
                <c-box v-html="removeIcon(getFacilityValue(facility, programTargets[j - 1]?.productFacilitiesComparation))" />
              </c-flex>
            </c-grid>
            <c-flex
              v-else
              w="100%"
              flex-direction="column"
              justify-content="center"
              align-items="center"
              text-align="center"
              p="6px"
              white-space="pre-line"
            >
              <inline-svg
                v-if="isCheckList(getFacilityValue(facility, programTargets[0]?.productFacilitiesComparation))"
                :src="
                  checkIcon(getFacilityValue(facility, programTargets[0]?.productFacilitiesComparation))
                    ? iconCircleCheck
                    : iconCircleClose
                "
                class="icon-list"
                :fill="
                  checkIcon(getFacilityValue(facility, programTargets[0]?.productFacilitiesComparation))
                    ? 'var(--program-400)'
                    : '#D32737'
                "
              />
              <c-box v-html="removeIcon(getFacilityValue(facility, programTargets[0]?.productFacilitiesComparation))" />
            </c-flex>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
import { reqProgramPage } from '@/requests/dietela-api/general/program-page'

export default {
  name: 'TableComparationTarget',
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      programFacilities: [],
      programTargets: [],
      iconCircleClose,
      iconCircleCheck,
    }
  },
  computed: {
    uniqueFacilitiesValue() {
      if (this.programTargets.length > 0) {
        let uniqueValuesMap = {}

        this.programTargets.forEach(item => {
          item?.productFacilitiesComparation.forEach(facilityItem => {
            const facility = facilityItem?.productFacilities?.facility
            const value = facilityItem?.value

            if (!uniqueValuesMap[facility]) {
              uniqueValuesMap[facility] = new Set()
            }

            uniqueValuesMap[facility].add(value)
          })
        })

        Object.keys(uniqueValuesMap).forEach(facility => {
          uniqueValuesMap[facility] = Array.from(uniqueValuesMap[facility])
        })

        return uniqueValuesMap
      }
      return {}
    },
    nonUniqueFacilitiesValue() {
      if (this.programTargets.length > 0) {
        let nonUniqueValuesMap = {}

        this.programTargets.forEach(item => {
          item.productFacilitiesComparation.forEach(facilityItem => {
            const facility = facilityItem.productFacilities.facility
            const value = facilityItem.value

            if (!nonUniqueValuesMap[facility]) {
              nonUniqueValuesMap[facility] = []
            }

            nonUniqueValuesMap[facility].push(value)
          })
        })

        return nonUniqueValuesMap
      }
      return {}
    },
    mapCountOfSameValues() {
      let valueCountMap = {}

      this.programTargets.forEach(item => {
        item?.productFacilitiesComparation.forEach(facilityItem => {
          const facility = facilityItem?.productFacilities?.facility
          const value = facilityItem?.value

          if (!valueCountMap[facility]) {
            valueCountMap[facility] = {}
          }

          if (!valueCountMap[facility][value]) {
            valueCountMap[facility][value] = 1
          } else {
            valueCountMap[facility][value]++
          }
        })
      })

      return valueCountMap
    },
  },
  watch: {
    slug: {
      immediate: true,
      handler(val) {
        if (val) {
          reqProgramPage.target(this.$store.getters.axios, {
            slug: val,
            params: {
              group: 'target',
            },
          })
            .then((res) => {
              this.programTargets = res.data?.data
              const firstDataFacilities = res.data?.data[0]?.productFacilitiesComparation.map(
                (item) => item?.productFacilities?.facility,
              ) || []
              this.programFacilities = firstDataFacilities
  
            })
        }
      },  
    },
  },
  methods: {
    getFacilityValue(label, data) {
      return data?.find(it => it?.productFacilities?.facility === label)?.value
    },
    isCheckList(value) {
      if (value && value?.includes('[x]') || value?.includes('[v]')) {
        return 1
      }
      return 0
    },
    checkIcon(value) {
      if (value && value?.includes('[x]')) {
        return 0
      }
      return 1
    },
    removeIcon(value) {
      return value.replace('[x]', '').replace('[v]', '')
    },
  },

}
</script>

<style scoped>
.table-comparation {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  overflow: hidden;
  border-radius: 8px;
}
.table-comparation td[colspan]:not([colspan='1']) {
  border-right: 1px solid var(--program-400);
}
.icon-list {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .icon-list {
    width: 20px;
    height: 20px;
  }
}
</style>
