var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": ['80%', '1056px'],
      "display": "flex",
      "mx": "auto",
      "flex-direction": ['column', 'row'],
      "justify-content": "center",
      "align-items": "center",
      "color": ['neutral.black', 'neutral.superDarkGray'],
      "font-size": ['14px', '28px'],
      "font-weight": ['600', '700'],
      "padding": ['10px 10px 0 10px', '10px'],
      "gap": ['0px', '10px'],
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_vm._v(" Kamu cocok program yang mana? "), _c('c-box', {
    attrs: {
      "as": "span"
    }
  }, [_vm._v(" Pilih sesuai target dan kondisi ")])], 1), _vm.programTargets.length ? _c('c-box', {
    attrs: {
      "max-width": "1057px",
      "width": "100%",
      "mx": "auto",
      "py": ['8px', '16px']
    }
  }, [_c('c-box', {
    staticClass: "table-comparation",
    attrs: {
      "pos": "relative",
      "display": ['none', 'table'],
      "as": "table",
      "width": "100%",
      "z-index": "3"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "thead",
      "color": "#FFF",
      "background-color": "program.400",
      "font-weight": "700",
      "border-radius": "8px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:first-child': {
          borderLeft: '1px solid',
          borderColor: 'program.400'
        },
        '>:last-child': {
          borderRight: '1px solid',
          borderColor: 'program.400'
        },
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #333333',
          borderLeft: '1px solid #333333'
        }
      },
      expression: "{\n            '>:first-child': {\n              borderLeft: '1px solid',\n              borderColor: 'program.400',\n            },\n            '>:last-child': {\n              borderRight: '1px solid',\n              borderColor: 'program.400',\n            },\n            '>:not(:last-child):not(:first-child)': {\n              borderRight: '1px solid #333333',\n              borderLeft: '1px solid #333333',\n            },\n          }"
    }],
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "text-align": "center",
      "font-size": "20px",
      "py": "1rem"
    }
  }, [_vm._v(" Fasilitas Layanan ")]), _vm._l(_vm.programTargets, function (item, i) {
    var _item$programsService;

    return _c('c-box', {
      key: "th-".concat(i),
      attrs: {
        "as": "th",
        "text-align": "center",
        "font-size": "20px",
        "py": "1rem"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$programsService = item.programsService) === null || _item$programsService === void 0 ? void 0 : _item$programsService.split(' ')[0]) + " ")]);
  })], 2)], 1), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> tr': {
          borderLeft: '1px solid var(--program-400)',
          borderRight: '1px solid var(--program-400)'
        }
      },
      expression: "{\n          '> tr': {\n            borderLeft: '1px solid var(--program-400)',\n            borderRight: '1px solid var(--program-400)',\n          },\n        }"
    }],
    attrs: {
      "as": "tbody",
      "background-color": "#FFF",
      "color": "#000",
      "font-size": "16px"
    }
  }, _vm._l(_vm.programFacilities, function (facility, i) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '>:not(:last-child):not(:first-child)': {
            borderRight: '1px solid #C4C4C4',
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n            '>:not(:last-child):not(:first-child)': {\n              borderRight: '1px solid #C4C4C4',\n              borderLeft: '1px solid #C4C4C4',\n            },\n          }"
      }],
      key: "tr-".concat(i),
      attrs: {
        "as": "tr",
        "border-bottom": i == _vm.programFacilities.length - 1 ? '1px solid var(--program-400)' : '1px solid #C4C4C4'
      }
    }, _vm._l(_vm.programTargets.length + 1, function (k) {
      var _vm$programTargets, _vm$programTargets2, _vm$programTargets3, _vm$programTargets4, _vm$programTargets5, _vm$programTargets6, _vm$programTargets7, _vm$programTargets8;

      return _c('c-box', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: k <= 2 || _vm.mapCountOfSameValues[facility][_vm.getFacilityValue(facility, (_vm$programTargets = _vm.programTargets[k - 2]) === null || _vm$programTargets === void 0 ? void 0 : _vm$programTargets.productFacilitiesComparation)] < 2 || _vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programTargets2 = _vm.programTargets[k - 2]) === null || _vm$programTargets2 === void 0 ? void 0 : _vm$programTargets2.productFacilitiesComparation)),
          expression: "\n              k <= 2 ||\n                mapCountOfSameValues[facility][\n                  getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation)\n                ] < 2 ||\n                isCheckList(getFacilityValue(facility, programTargets[k - 2]?.productFacilitiesComparation))\n            "
        }],
        key: "td-".concat(i, "-").concat(k),
        attrs: {
          "as": "td",
          "text-align": k === 1 ? 'left' : 'center',
          "colspan": k === 1 || _vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programTargets3 = _vm.programTargets[k - 2]) === null || _vm$programTargets3 === void 0 ? void 0 : _vm$programTargets3.productFacilitiesComparation)) ? '1' : _vm.mapCountOfSameValues[facility][_vm.getFacilityValue(facility, (_vm$programTargets4 = _vm.programTargets[k - 2]) === null || _vm$programTargets4 === void 0 ? void 0 : _vm$programTargets4.productFacilitiesComparation)]
        }
      }, [k === 1 ? _c('c-box', {
        attrs: {
          "width": "100%",
          "padding": "12px"
        }
      }, [_vm._v(" " + _vm._s(facility) + " ")]) : _c('c-box', {
        attrs: {
          "width": "100%",
          "padding": "12px",
          "display": "flex",
          "justify-content": "center",
          "white-space": "pre-line"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programTargets5 = _vm.programTargets[k - 2]) === null || _vm$programTargets5 === void 0 ? void 0 : _vm$programTargets5.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programTargets6 = _vm.programTargets[k - 2]) === null || _vm$programTargets6 === void 0 ? void 0 : _vm$programTargets6.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programTargets7 = _vm.programTargets[k - 2]) === null || _vm$programTargets7 === void 0 ? void 0 : _vm$programTargets7.productFacilitiesComparation)) ? 'var(--program-400)' : '#D32737'
        }
      }) : _vm._e(), _c('c-box', {
        domProps: {
          "innerHTML": _vm._s(_vm.removeIcon(_vm.getFacilityValue(facility, (_vm$programTargets8 = _vm.programTargets[k - 2]) === null || _vm$programTargets8 === void 0 ? void 0 : _vm$programTargets8.productFacilitiesComparation)))
        }
      })], 1)], 1);
    }), 1);
  }), 1)], 1), _c('c-box', {
    attrs: {
      "pos": "relative",
      "display": ['block', 'none'],
      "z-index": "3",
      "width": "100%",
      "padding": "0 10px"
    }
  }, [_c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:first-child) > div': {
          borderLeft: '1px solid #333333'
        }
      },
      expression: "{\n          '> :not(:first-child) > div': {\n            borderLeft: '1px solid #333333',\n          },\n        }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.programTargets.length, ", 1fr)"),
      "background-color": "program.400",
      "color": "#FFF",
      "font-size": "14px",
      "font-weight": "500",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "border": "1px solid",
      "border-color": "program.400"
    }
  }, _vm._l(_vm.programTargets, function (item) {
    var _item$programsService2;

    return _c('c-box', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "w": "100%",
        "py": "6px"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "flex-direction": "column",
        "align-items": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "14px"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$programsService2 = item.programsService) === null || _item$programsService2 === void 0 ? void 0 : _item$programsService2.split(' ')[0]) + " ")])], 1)], 1);
  }), 1), _c('c-box', {
    attrs: {
      "p": "16px 8px",
      "color": "#000",
      "font-size": "12px",
      "font-weight": "400",
      "background-color": "#FFF",
      "border-bottom-left-radius": "8px",
      "border-bottom-right-radius": "8px",
      "border-left": "1px solid",
      "border-right": "1px solid",
      "border-bottom": "1px solid",
      "border-color": "program.400"
    }
  }, _vm._l(_vm.programFacilities, function (facility, index) {
    var _vm$programTargets$, _vm$programTargets$2, _vm$programTargets$3, _vm$programTargets$4, _vm$programTargets$5, _vm$programTargets$6;

    return _c('c-box', {
      key: "facility-".concat(index),
      attrs: {
        "mb": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "p": "6px 12px",
        "background-color": "program.100",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(facility) + " ")]), _vm.mapCountOfSameValues[facility][_vm.getFacilityValue(facility, (_vm$programTargets$ = _vm.programTargets[0]) === null || _vm$programTargets$ === void 0 ? void 0 : _vm$programTargets$.productFacilitiesComparation)] < 2 || _vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programTargets$2 = _vm.programTargets[0]) === null || _vm$programTargets$2 === void 0 ? void 0 : _vm$programTargets$2.productFacilitiesComparation)) ? _c('c-grid', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '> :not(:first-child)': {
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n              '> :not(:first-child)': {\n                borderLeft: '1px solid #C4C4C4',\n              },\n            }"
      }],
      attrs: {
        "w": "100%",
        "template-columns": "repeat(".concat(_vm.programTargets.length, ", 1fr)"),
        "text-align": "center"
      }
    }, _vm._l(_vm.programTargets.length, function (j) {
      var _vm$programTargets9, _vm$programTargets10, _vm$programTargets11, _vm$programTargets12;

      return _c('c-flex', {
        key: j,
        attrs: {
          "w": "100%",
          "p": "6px",
          "flex-direction": "column",
          "align-items": "center",
          "white-space": "pre-line"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programTargets9 = _vm.programTargets[j - 1]) === null || _vm$programTargets9 === void 0 ? void 0 : _vm$programTargets9.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programTargets10 = _vm.programTargets[j - 1]) === null || _vm$programTargets10 === void 0 ? void 0 : _vm$programTargets10.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programTargets11 = _vm.programTargets[j - 1]) === null || _vm$programTargets11 === void 0 ? void 0 : _vm$programTargets11.productFacilitiesComparation)) ? 'var(--program-400)' : '#D32737'
        }
      }) : _vm._e(), _c('c-box', {
        domProps: {
          "innerHTML": _vm._s(_vm.removeIcon(_vm.getFacilityValue(facility, (_vm$programTargets12 = _vm.programTargets[j - 1]) === null || _vm$programTargets12 === void 0 ? void 0 : _vm$programTargets12.productFacilitiesComparation)))
        }
      })], 1);
    }), 1) : _c('c-flex', {
      attrs: {
        "w": "100%",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "text-align": "center",
        "p": "6px",
        "white-space": "pre-line"
      }
    }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programTargets$3 = _vm.programTargets[0]) === null || _vm$programTargets$3 === void 0 ? void 0 : _vm$programTargets$3.productFacilitiesComparation)) ? _c('inline-svg', {
      staticClass: "icon-list",
      attrs: {
        "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programTargets$4 = _vm.programTargets[0]) === null || _vm$programTargets$4 === void 0 ? void 0 : _vm$programTargets$4.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
        "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programTargets$5 = _vm.programTargets[0]) === null || _vm$programTargets$5 === void 0 ? void 0 : _vm$programTargets$5.productFacilitiesComparation)) ? 'var(--program-400)' : '#D32737'
      }
    }) : _vm._e(), _c('c-box', {
      domProps: {
        "innerHTML": _vm._s(_vm.removeIcon(_vm.getFacilityValue(facility, (_vm$programTargets$6 = _vm.programTargets[0]) === null || _vm$programTargets$6 === void 0 ? void 0 : _vm$programTargets$6.productFacilitiesComparation)))
      }
    })], 1)], 1);
  }), 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }